import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import axios, { AxiosRequestConfig } from "axios"
import { graphql, Link, useStaticQuery, navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "../styles/pages/join.module.scss"
import Hero from "../images/fallbacks/join.jpg"
import HeroWebp from "../images/webp/join.webp"
import HeroSmall from "../images/fallbacks/join-small.jpg"
import HeroSmallWebp from "../images/webp/join-small.webp"
import Ethan from "../images/fallbacks/ethan.jpg"
import Brenda from "../images/fallbacks/brenda.jpg"
import EthanSmall from "../images/fallbacks/ethan-small.jpg"
import BrendaSmall from "../images/fallbacks/brenda-small.jpg"
import EthanWebp from "../images/webp/ethan.webp"
import BrendaWebp from "../images/webp/brenda.webp"
import EthanWebpSmall from "../images/webp/ethan-small.webp"
import BrendaWebpSmall from "../images/webp/brenda-small.webp"

import Recaptcha from "react-google-recaptcha"

const JoinUs = () => {
  const { register, handleSubmit, watch, reset, errors, getValues } = useForm()
  const [token, setToken] = useState("")
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState("")
  const [success, setSuccess] = useState(false)
  const [jobdata, setData] = useState([])

  const data = useStaticQuery(
    graphql`
      query {
        allMdx {
          edges {
            node {
              id
              slug
              fields {
                slug
              }
              frontmatter {
                title
                experience
                type
              }
            }
          }
        }
      }
    `
  )

  console.log(data)

  const submitHandler = (values: any, e: any) => {
    setLoading(true)
    const formData = new FormData(e.target)

    const options: AxiosRequestConfig = {
      method: "POST",
      url: `${process.env.GATSBY_EMAIL_URL}/join`,
      headers: {
        "Content-Type": "multipart/form-data",
        "content-type":
          "multipart/form-data; boundary=---011000010111000001101001",
      },
      data: formData,
    }

    axios
      .request(options)
      .then(function (response) {
        navigate("/success")
      })
      .catch(function (error) {
        console.error(error)

        setMessage(
          "We were unable to send your message. Please try again later."
        )
        setSuccess(false)
      })

    setLoading(false)
  }
  useEffect(() => {
    axios
      .get(`https://jenga-tech-solutions-limited.breezy.hr/json`)
      .then(response => setData(response.data))
      .catch(function (error) {
        console.error(error)
      })
  }, [])

  return (
    <Layout>
      <SEO title="Join" />
      <section className={styles.heroWrapper}>
        <picture className={styles.heroImgWrapper}>
          <source
            media="(max-width: 700px)"
            srcSet={HeroSmallWebp}
            className={styles.heroImg}
          />
          <source
            media="(max-width: 700px)"
            srcSet={HeroSmall}
            className={styles.heroImg}
          />
          <source
            media="(min-width: 701px)"
            srcSet={HeroWebp}
            className={styles.heroImg}
          />
          <img
            src={Hero}
            alt="Jenga team working in the office"
            className={styles.heroImg}
          />
        </picture>
        <div className={styles.heroContent}>
          <h2 className={styles.heroTitle}>Grow As A Developer</h2>
          <span className={styles.heroTitleBorder}></span>
          <p className={styles.heroSubtitle}>
            We give you the opportunity to work with clients across the globe
            right from our Nairobi office.
          </p>
          <a href="#careers" className={styles.heroBtn}>
            Join Us
          </a>
        </div>
      </section>
      <section className={styles.testimonialsWrapper}>
        <div className={styles.testimonials}>
          <h2 className={styles.testimonialsTitle}>Working at Jenga</h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="27.88"
            viewBox="0 0 30 27.88"
            className={styles.testimonialsIcon}
          >
            <g>
              <path
                fill="currentColor"
                d="M12.917 13.562v5.006a1.229 1.229 0 0 1-1.229 1.232c-2.422 0-3.74 2.484-3.923 7.388h3.923a1.229 1.229 0 0 1 1.229 1.229v10.57a1.229 1.229 0 0 1-1.229 1.229H1.229A1.229 1.229 0 0 1 0 38.984v-10.57A27.106 27.106 0 0 1 .7 22a15.428 15.428 0 0 1 2.183-5.075A10.655 10.655 0 0 1 6.6 13.538a10.557 10.557 0 0 1 5.093-1.205 1.229 1.229 0 0 1 1.224 1.229zM28.771 19.8A1.23 1.23 0 0 0 30 18.568v-5.006a1.229 1.229 0 0 0-1.229-1.229 10.209 10.209 0 0 0-8.8 4.595A15.435 15.435 0 0 0 17.786 22a27.126 27.126 0 0 0-.7 6.412v10.57a1.229 1.229 0 0 0 1.229 1.229h10.456A1.229 1.229 0 0 0 30 38.984v-10.57a1.229 1.229 0 0 0-1.229-1.229H24.9c.184-4.903 1.482-7.385 3.871-7.385z"
                transform="translate(0 -12.333)"
              />
            </g>
          </svg>
          <div className={styles.testimonialsCard}>
            <p className={styles.testimonialsCardText}>
              I started by two weeks of training to ramp up on a language and a
              framework I wasn’t fluent in. By week 3, I was a fully integrated
              team member in the tech team of a French AI start-up, developing
              the application their clients will use all across the globe.
            </p>
            <div className={styles.testimonialsCardDev}>
              <picture className={styles.testimonialsCardImg}>
                <source srcSet={EthanSmall} media="(max-width: 700px)" />
                <source srcSet={EthanWebpSmall} media="(max-width: 700px)" />
                <source srcSet={EthanWebp} media="(min-width: 701px)" />
                <source srcSet={Ethan} media="(min-width: 701px)" />
                <img src={Ethan} alt="Jenga Developer" />
              </picture>
              <div>
                <h3>Ethan</h3>
                <p>
                  Bachelor of Business Information Technology Graduate,
                  Strathmore University
                </p>
              </div>
            </div>
          </div>
          <div className={styles.testimonialsCard}>
            <p className={styles.testimonialsCardText}>
              Mock projects were great to learn the basics, and then I joined
              Jenga where I am developing real solutions for real organizations
              with a real impact!
            </p>
            <div className={styles.testimonialsCardDev}>
              <picture className={styles.testimonialsCardImg}>
                <source srcSet={BrendaSmall} media="(max-width: 700px)" />
                <source srcSet={BrendaWebpSmall} media="(max-width: 700px)" />
                <source srcSet={BrendaWebp} media="(min-width: 701px)" />
                <source srcSet={Brenda} media="(min-width: 701px)" />
                <img src={Brenda} alt="Jenga Developer" />
              </picture>
              <div>
                <h3>Brenda</h3>
                <p>Graduate, Moringa School</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.careers} id="careers">
        <h2 className={styles.careersTitle}>Careers at Jenga</h2>
        {jobdata.length > 0 ? (
          jobdata.map((item: any) => (
            <div className={styles.careersCategory} key={item.id}>
              <div>
                <h3>{item.name}</h3>
                <p>{item.type.name} </p>
              </div>
              <Link
                to={item.url}
                className={styles.btnOutlinePrimary}
                target="_blank"
              >
                Apply
              </Link>
            </div>
          ))
        ) : (
          <div className={styles.careersNoCareers}>
            <h3>Sorry, no positions available at this time</h3>
          </div>
        )}
      </section>
    </Layout>
  )
}

export default JoinUs
